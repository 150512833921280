// import React from "react";
// import { useTable } from "react-table";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
// } from "@mui/material";

// const StyledTable = (props) => {
//   const { columns, data, handleSelectRow } = props;

//   const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
//     useTable({
//       columns,
//       data,
//     });

//   const rowProps = (row) => ({
//     ...row.getRowProps(),
//     onClick: () => handleSelectRow && handleSelectRow(row.original),
//     style: {
//       cursor: handleSelectRow ? "pointer" : "default",
//       backgroundColor: "white", // Set row background color
//       "&:hover": {
//         backgroundColor: "#f5f5f5", // Row hover effect
//       },
//     },
//   });

//   return (
//     <TableContainer
//       component={Paper}
//       style={{ maxHeight: "500px", overflow: "auto" }}
//     >
//       <Table {...getTableProps()}>
//         <TableHead>
//           {headerGroups.map((headerGroup) => (
//             <TableRow {...headerGroup.getHeaderGroupProps()}>
//               {headerGroup.headers.map((column) => (
//                 <TableCell {...column.getHeaderProps()}>
//                   {column.render("Header")}
//                 </TableCell>
//               ))}
//             </TableRow>
//           ))}
//         </TableHead>
//         <TableBody {...getTableBodyProps()}>
//           {rows.map((row) => {
//             prepareRow(row);
//             return (
//               <TableRow {...rowProps(row)}>
//                 {row.cells.map((cell) => (
//                   <TableCell {...cell.getCellProps()}>
//                     {cell.render("Cell")}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             );
//           })}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// };

// export default StyledTable;

import React, { useState, useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TableSortLabel,
} from "@mui/material";
import moment from "moment";

const StyledTable = (props) => {
  const { columns, data, handleSelectRow } = props;

  const [searchText, setSearchText] = useState("");

  // Filter data based on search text
  const filteredData = useMemo(() => {
    if (!searchText) return data;
    return data.filter((row) =>
      Object.values(row).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [data, searchText]);

  // Enhanced Columns with Date Sorting
  const enhancedColumns = useMemo(() => {
    return columns.map((column) => {
      if (column.isDateField) {
        return {
          ...column,
          sortType: (rowA, rowB) => {
            const dateA = moment(
              rowA.original[column.accessor],
              moment.ISO_8601
            );
            const dateB = moment(
              rowB.original[column.accessor],
              moment.ISO_8601
            );

            if (dateA.isValid() && dateB.isValid()) {
              return dateA - dateB; // Ascending order
            }
            // Fallback for invalid dates
            return rowA.original[column.accessor]?.localeCompare(
              rowB.original[column.accessor]
            );
          },
        };
      }
      return column;
    });
  }, [columns]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: enhancedColumns,
        data: filteredData,
      },
      useSortBy
    );

  const rowProps = (row) => ({
    ...row.getRowProps(),
    onClick: () => handleSelectRow && handleSelectRow(row.original),
    style: {
      cursor: handleSelectRow ? "pointer" : "default",
      backgroundColor: "white",
    },
  });

  return (
    <div style={{ marginBottom: "1em" }}>
      {/* Search Box */}
      <TextField
        variant="outlined"
        label="Search"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{
          marginBottom: "1em",
          width: "300px",
        }}
      />

      {/* Table */}
      <TableContainer
        component={Paper}
        style={{ maxHeight: "500px", overflow: "auto" }}
      >
        <Table {...getTableProps()} stickyHeader>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <TableSortLabel
                      active={column.isSorted}
                      direction={column.isSortedDesc ? "desc" : "asc"}
                    >
                      {column.render("Header")}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...rowProps(row)}>
                  {row.cells.map((cell) => (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default StyledTable;
