import React, { useState } from "react";
import { AppBar, Tabs, Tab, Typography, Box } from "@mui/material";
import { useSwipeable } from "react-swipeable";
import { styled } from "@mui/material/styles";
import useIsMobile from "../hooks/useIsMobile";

// Custom Styled Tab
const StyledTab = styled(Tab)({
  textTransform: "none",
  backgroundColor: "transparent",
  fontSize: "18px",
});

// Custom Styled Tabs
const StyledTabs = styled(Tabs)({
  backgroundColor: "transparent",
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const SwipeableTabs = (props) => {
  const isMobile = useIsMobile();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => setValue((v) => Math.min(v + 1, props.tabs.length - 1)),
    onSwipedRight: () => setValue((v) => Math.max(v - 1, 0)),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        width: "100%",
        borderRadius: "30px",
        height: isMobile ? "auto" : "100%",
      }}
    >
      <StyledTabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs"
      >
        {props.tabs.map((tab, index) => (
          <StyledTab key={index} label={tab.label} />
        ))}
      </StyledTabs>
      <Box {...swipeHandlers}>
        {props.tabs.map((tab, index) => (
          <TabPanel key={index} value={value} index={index}>
            {tab.content}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default SwipeableTabs;
