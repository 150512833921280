export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const JWT = "JWT";
export const GOOGLE_TOKEN = "GOOGLE_TOKEN";
export const applicationJson = "application/json";

export const getToken = async (key: string) => {
  try {
    const value = sessionStorage.getItem(key);
    if (value !== null) {
      return value;
    }
  } catch (e) {
    throw e;
  }
};

export const storeToken = async (key: string, value: string) => {
  console.log("STORE TOKEN INVOKATION");
  try {
    sessionStorage.setItem(key, value);
  } catch (e) {
    throw e;
  }
};

export const revokeToken = async (key: string) => {
  console.log("revoke token");
  try {
    sessionStorage.removeItem(key);
  } catch (e) {
    throw e;
  }
};

const nodeEnv = process.env.NODE_ENV;

export const blurredForbiddenStyle = {
  filter: "blur(8px)",
  userSelect: "none",
  pointerEvents: "none",
};

export const UNSUBSCRIBE_MESSAGE = `\n\nReply STOP to opt out`;

export const CLIENT_URL =
  nodeEnv === "production"
    ? "https://sms.launchsitellc.com/"
    : "http://localhost:3000/";

// export const ROOT_URL =
//   nodeEnv === "production"
//     ? "https://5a3es437jl.execute-api.us-west-2.amazonaws.com/beta/"
//     : "https://5a3es437jl.execute-api.us-west-2.amazonaws.com/beta/";

export const ROOT_URL =
  nodeEnv === "production"
    ? "https://ai.virtualcontactai.com"
    : "http://localhost:5000/";

console.log(`the node env is: ${nodeEnv}`);
