import moment from "moment";

export const interactionsColumns = [
  {
    Header: "Name",
    accessor: "userName",
  },
  {
    Header: "Phone Number",
    accessor: "userPhone",
  },
  {
    Header: "Promo Code",
    accessor: "promoCode",
  },
  {
    Header: "Redeemed",
    accessor: "redeemed",
    Cell: ({ value }) => (value ? "Yes" : "No"),
  },
  {
    Header: "Message Status",
    accessor: "messageStatus",
  },
  {
    Header: "Last Updated",
    accessor: "updatedAt",
    Cell: ({ value }) => new Date(value).toLocaleString(),
    isDateField: true, // Mark this column as a date field
  },
];

export const businessColumns = [
  {
    Header: "Business Name",
    accessor: "businessName",
  },
  {
    Header: "Phone Number",
    accessor: "phoneNumber",
  },
  {
    Header: "Address",
    accessor: "fullAddress",
    Cell: ({ value }) => {
      console.log("value: ", value);
      return `${value.address1}, ${value.city}, ${value.state?.code}, ${value.zip}`;
    },
  },
];

export const campaignColumns = [
  {
    Header: "Business ID",
    accessor: "businessId",
  },
  {
    Header: "Business Name",
    accessor: "businessName",
  },

  {
    Header: "Created At",
    accessor: "createdAt",
    Cell: ({ value }) => moment(value).format("MMMM Do YYYY, h:mm A"), // Display formatted date
    isDateField: true, // Enable date-specific sorting
  },
  {
    Header: "Campaign Name",
    accessor: "campaignName",
  },
  {
    Header: "Message Body",
    accessor: "messageBody",
  },
  {
    Header: "Message Image",
    accessor: "messageImage",
    Cell: ({ value }) => (
      <a href={value} target="_blank" rel="noopener noreferrer">
        View Image
      </a>
    ),
  },
  {
    Header: "Audience File",
    accessor: "audienceFileS3Key",
    Cell: ({ value }) => value.split("/").pop(),
  },
  {
    Header: "Promotion Code",
    accessor: "promoCode",
  },
  {
    Header: "Promotion Type",
    accessor: "promotion.promoType",
  },
  {
    Header: "Discount",
    accessor: "promotion.discount",
  },
  {
    Header: "Expiration Date",
    accessor: "promotion.expiration",
    Cell: ({ value }) =>
      value ? new Date(value).toLocaleDateString() : "No Expiry", // Formatting the expiration date or displaying 'No Expiry'
    isDateField: true, // Mark this column as a date field
  },
];
