import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../axiosConfig";

export const createBusiness = createAsyncThunk(
  "business/createBusiness",
  async (businessData) => {
    const response = await axiosInstance.post(`sms/business`, businessData);
    return response.data;
  }
);

export const getBusiness = createAsyncThunk(
  "business/getBusiness",
  async (businessId) => {
    const response = await axiosInstance.get(`sms/business/${businessId}`);
    return response.data;
  }
);

export const getBusinesses = createAsyncThunk(
  "business/getBusinesses",
  async () => {
    const response = await axiosInstance.get(`sms/business`);
    return response.data;
  }
);

export const getNumbers = createAsyncThunk(
  "business/getNumbers",
  async (data) => {

    const {businessId} = data

    let response;

    if (!businessId) {
      response = await axiosInstance.get(`sms/business/listNumbers`);
    } else {
      response = await axiosInstance.get(
        `sms/business/${businessId}/listNumbers`
      );
    }

    return response.data
  }
);


export const getMessagingServices = createAsyncThunk(
  "business/getMessagingServices",
  async (data) => {

    const {businessId} = data

    let response;

    if (!businessId) {
      response = await axiosInstance.get(`sms/business/listMessagingServices`);
    } else {
      response = await axiosInstance.get(
        `sms/business/${businessId}/listMessagingServices`
      );
    }

    return response.data
  }
);



const businessSlice = createSlice({
  name: "business",
  initialState: {
    business: null,
    businesses: [],
    numbers: [],
    messagingServices: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBusinesses.fulfilled, (state, action) => {
        state.loading = false;
        state.businesses = action.payload;
      })
      .addCase(getBusiness.fulfilled, (state, action) => {
        state.loading = false;
        state.business = action.payload;
      })
      .addCase(createBusiness.fulfilled, (state, action) => {
        state.loading = false;
        state.business = action.payload;
      })
      .addCase(getNumbers.fulfilled, (state, action) => {
        state.loading = false;
        state.numbers = action.payload;
      })
      .addCase(getMessagingServices.fulfilled, (state, action) => {
        state.loading = false;
        state.messagingServices = action.payload;
      })
      .addMatcher(
        (action) =>
          action.type.startsWith("business/") &&
          action.type.endsWith("/pending"),
        (state) => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        (action) =>
          action.type.startsWith("business/") &&
          action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

export default businessSlice.reducer;
