import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, ButtonBase, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getCampaigns } from "../models/campaign/campaignSlice";
import { getBusiness } from "../models/business/businessSlice";
import { getUsers } from "../models/user/userSlice";
import StyledTable from "../components/StyledTable";
import { campaignColumns } from "../config/columns";
import { IconButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const Business = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { businessId } = useParams();

  const businessSlice = useSelector((state) => state.business);
  const campaignsSlice = useSelector((state) => state.campaign);
  const userSlice = useSelector((state) => state.user);

  const { business } = businessSlice;
  const { campaigns } = campaignsSlice;
  const { users } = userSlice;
  const isAdmin = sessionStorage.getItem("role") === "admin";

  const dispatch = useDispatch();

  const handleRowSelect = (row) => {
    console.log("Selected row:", row);
    navigate(`${location.pathname}/campaign/${row?.campaignId}`);
  };

  useEffect(() => {
    if (businessId) {
      dispatch(getBusiness(businessId));
      dispatch(getCampaigns(businessId));
      dispatch(getUsers(businessId));
    }
  }, [businessId]);

  const campaignsComponent = (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1em",
          marginTop: "2em",
        }}
      >
        <h2>Campaigns</h2>

        {isAdmin && (
          <IconButton
            color="primary"
            onClick={() => navigate(`${location.pathname}/campaigns`)}
            style={{ alignSelf: "center" }}
          >
            <AddCircleIcon fontSize="large" />
          </IconButton>
        )}
      </div>

      <div
        style={{
          marginTop: "2em",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
          gap: "2em",
        }}
      >
        <StyledTable
          columns={campaignColumns}
          data={campaigns}
          handleSelectRow={handleRowSelect}
        />
      </div>
    </>
  );

  const usersComponent = (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1em",
          marginTop: "2em",
        }}
      >
        <h2>Users</h2>

        {isAdmin && (
          <IconButton
            color="primary"
            onClick={() => navigate(`${location.pathname}/users`)}
            style={{ alignSelf: "center" }}
          >
            <AddCircleIcon fontSize="large" />
          </IconButton>
        )}
      </div>

      <div
        style={{
          marginTop: "2em",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "75%",
          gap: "2em",
        }}
      >
        {users?.map((usr) => {
          return (
            <Paper
              style={{
                padding: "10px",
                boxShadow: "0px 3px 5px rgba(0,0,0,0.2)",
                width: "300px",
                height: "200px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div>
                <h3
                  style={{
                    margin: "0",
                    fontWeight: "bold",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {usr.email}
                </h3>{" "}
                <h4
                  style={{ margin: "0" }}
                >{`${usr.firstName} ${usr.lastName}`}</h4>
                <p style={{ margin: "0" }}>
                  Created: {new Date(usr.createdAt).toLocaleDateString()}
                </p>
              </div>
            </Paper>
          );
        })}
      </div>
    </>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        padding: "2em",
      }}
    >
      <h1>{business?.businessName}</h1>
      <h3 style={{ margin: "0px" }}>{business?.phoneNumber}</h3>

      {usersComponent}
      {campaignsComponent}
    </div>
  );
};

export default Business;
