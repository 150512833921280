import React, { useState, useEffect } from "react";
import {
  Switch,
  Typography,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Popover from "@mui/material/Popover";
import FileDropzone from "./FileDropzone";
import StyledTable from "./StyledTable";

const AudienceTab = ({ isMobile, handleFileUpload, campaign, setCampaign }) => {
  const [isFileMode, setIsFileMode] = useState(true);
  const [manualEntries, setManualEntries] = useState([]);
  const [fileErrors, setFileErrors] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [newEntry, setNewEntry] = useState({ name: "", phoneNumber: "" });

  const handleAddEntry = () => {
    if (newEntry.name && newEntry.phoneNumber) {
      setManualEntries((prev) => [...prev, newEntry]);
      setNewEntry({ name: "", phoneNumber: "" });
    }
  };

  const handleRemoveEntry = (index) => {
    setManualEntries((prev) => prev.filter((_, i) => i !== index));
  };

  const handleRemoveFile = () => {
    setCampaign((prevCampaign) => ({
      ...prevCampaign,
      base64FileContent: null,
      audienceFileName: null,
      audienceFileSize: null,
    }));
    setManualEntries([]);
    setFileErrors(null);
  };

  useEffect(() => {
    if (campaign?.base64FileContent) {
      const decodedContent = atob(campaign.base64FileContent);
      const rows = decodedContent.split("\n").filter(Boolean);
      const headers = rows[0]
        .split(",")
        .map((header) => header.trim().toLowerCase());
      const dataRows = rows.slice(1);

      // Validate headers
      if (
        !headers.includes("name") ||
        !headers.some((h) => ["phone number", "phonenumber"].includes(h))
      ) {
        setFileErrors(
          `Invalid headers. Required fields are 'Name' and 'Phone Number'. Provided: ${headers.join(
            ", "
          )}`
        );
        setManualEntries([]);
        return;
      }

      const nameIndex = headers.indexOf("name");
      const phoneIndex = headers.findIndex((h) =>
        ["phone number", "phonenumber"].includes(h)
      );

      const entries = dataRows.map((row) => {
        const columns = row.split(",").map((col) => col.trim());
        return {
          name: columns[nameIndex] || "",
          phoneNumber: columns[phoneIndex] || "",
        };
      });

      setManualEntries(entries);
      setFileErrors(null);
    }
  }, [campaign?.base64FileContent]);

  useEffect(() => {
    if (manualEntries.length > 0) {
      saveToCampaign();
    }
  }, [manualEntries]);

  const saveToCampaign = () => {
    const csvHeaders = "Name,Phone Number\n";
    const csvRows = manualEntries
      .map((entry) => `${entry.name},${entry.phoneNumber}`)
      .join("\n");

    const csvContent = csvHeaders + csvRows;
    const base64FileContent = btoa(csvContent);

    setCampaign((prevCampaign) => ({
      ...prevCampaign,
      base64FileContent,
      audienceFileName: "manual_entries.csv",
      audienceFileSize: base64FileContent.length,
    }));
  };

  const handleToggleMode = () => {
    setIsFileMode(!isFileMode);
    setFileErrors(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEntry((prev) => ({ ...prev, [name]: value }));
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        gap: "1em",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: isMobile ? "100%" : "350px",
        }}
      >
        <Typography variant="h6">Upload File</Typography>
        <Switch checked={!isFileMode} onChange={handleToggleMode} />
        <Typography variant="h6">Manual Entry</Typography>
      </div>

      {isFileMode ? (
        <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
          <FileDropzone
            onFileUpload={handleFileUpload}
            fileName={campaign?.audienceFileName}
            fileSize={campaign?.audienceFileSize}
            acceptedFileTypes={{ "text/csv": [".csv"] }}
            dropzoneMessage="Drag and drop your audience CSV file here, or click to select files"
          />
          {campaign?.audienceFileName && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleRemoveFile}
            >
              Remove File
            </Button>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1em",
            width: isMobile ? "100%" : "350px",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <TextField
              label="Name"
              name="name"
              value={newEntry.name}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              label="Phone Number"
              name="phoneNumber"
              value={newEntry.phoneNumber}
              onChange={handleInputChange}
              fullWidth
            />
            <IconButton
              color="primary"
              onClick={handleAddEntry}
              style={{ alignSelf: "center" }}
            >
              <AddCircleIcon fontSize="large" />
            </IconButton>
          </div>
        </div>
      )}

      {fileErrors ? (
        <div style={{ cursor: "pointer" }}>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <ErrorOutlineIcon color="error" />
            <Typography
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              The file is invalid
            </Typography>
          </div>
          <Popover
            id="mouse-over-popover"
            sx={{ pointerEvents: "none" }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography style={{ padding: "10px" }}>{fileErrors}</Typography>
          </Popover>
        </div>
      ) : (
        campaign?.base64FileContent &&
        manualEntries.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <CheckCircleIcon style={{ color: "green" }} />
            <Typography>
              This file is valid, and the audience size is{" "}
              {manualEntries.length}
            </Typography>
          </div>
        )
      )}

      {manualEntries.length > 0 && (
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: isMobile ? "100%" : "60%",
          }}
        >
          <StyledTable
            columns={[
              { Header: "Name", accessor: "name" },
              { Header: "Phone Number", accessor: "phoneNumber" },
              {
                header: "Actions",
                accessor: "actions",
                Cell: ({ row }) => (
                  <IconButton
                    color="secondary"
                    onClick={() => handleRemoveEntry(row.index)}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                ),
              },
            ]}
            data={manualEntries}
          />
        </div>
      )}
    </div>
  );
};

export default AudienceTab;
