import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import { Card, CardContent, Typography, Chip } from "@mui/material";
import moment from "moment";
import StyledTable from "./StyledTable";
import { getCampaignInteractions } from "../models/campaign/campaignSlice";

const InteractionsTab = () => {
  const campaignSlice = useSelector((state) => state.campaign);
  const { campaign, campaignInteractions } = campaignSlice;
  const dispatch = useDispatch();

  const POLL_INTERVAL = 5000; // Poll every 5 seconds

  useEffect(() => {
    let intervalId;

    if (campaign && campaign?.campaignId && campaign?.businessId) {
      const { campaignId, businessId } = campaign;

      // Function to fetch interactions
      const fetchInteractions = () => {
        dispatch(
          getCampaignInteractions({
            businessId: businessId,
            campaignId: campaignId,
          })
        );
      };

      // Fetch immediately and start polling
      fetchInteractions();
      intervalId = setInterval(fetchInteractions, POLL_INTERVAL);
    }

    // Cleanup interval on unmount or campaign change
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [campaign?.campaignId, campaign?.businessId, dispatch]);

  // Aggregate statuses
  const statusCounts = React.useMemo(() => {
    return campaignInteractions?.reduce((acc, interaction) => {
      const status = interaction.messageStatus || "Unknown";
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});
  }, [campaignInteractions]);

  // Convert status counts to an array for pie chart
  const pieData = React.useMemo(() => {
    return Object.entries(statusCounts || {}).map(([status, count]) => ({
      name: status,
      value: count,
    }));
  }, [statusCounts]);

  const pieColors = [
    "#4caf50", // Green for Delivered
    "#2196f3", // Blue for Sent
    "#f44336", // Red for Errors
    "#ff9800", // Orange for Other statuses
    "#9c27b0", // Purple for Unknown
  ];

  // Generate time series data
  const timeSeriesData = React.useMemo(() => {
    const timeSeries = campaignInteractions?.reduce((acc, interaction) => {
      const date = new Date(interaction.updatedAt).toISOString().split("T")[0];
      const status = interaction.messageStatus || "Unknown";

      if (!acc[date]) {
        acc[date] = { date };
        Object.keys(statusCounts).forEach((key) => (acc[date][key] = 0));
      }

      acc[date][status] += 1;
      return acc;
    }, {});

    return Object.values(timeSeries || {});
  }, [campaignInteractions, statusCounts]);

  // Adjust table columns for Chips and N/A logic
  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: "User Name",
        accessor: "userName",
      },
      {
        Header: "Phone Number",
        accessor: "userPhone",
      },
      {
        Header: "Promo Code",
        accessor: "promoCode",
        Cell: ({ value }) => (value ? value : "N/A"),
      },
      {
        Header: "Redeemed",
        accessor: "redeemed",
        Cell: ({ value }) => (value ? "Yes" : "N/A"),
      },
      {
        Header: "Message Status",
        accessor: "messageStatus",
        Cell: ({ value }) => {
          let color;
          if (value === "delivered") color = "#4caf50"; // Green
          else if (value === "sent") color = "#2196f3"; // Blue
          else if (value === "errored") color = "#f44336"; // Red
          else color = "#9c27b0"; // Purple for Unknown

          return (
            <Chip
              label={value || "Unknown"}
              style={{
                backgroundColor: color,
                color: "white",
                fontWeight: "bold",
                borderRadius: "5px",
              }}
            />
          );
        },
      },
      {
        Header: "Last Updated",
        accessor: "updatedAt",
        Cell: ({ value }) => moment(value).format("MMMM Do YYYY, h:mm A"),
      },
    ];
  }, []);

  return (
    <div style={{ padding: "20px", height: "100%" }}>
      {/* Campaign Summary */}
      <Card style={{ marginBottom: "20px" }}>
        <CardContent>
          <Typography variant="h6">
            Campaign Summary @ {moment().format("MMMM Do YYYY, h:mm A")}
          </Typography>
          {Object.entries(statusCounts || {}).map(([status, count]) => (
            <Typography key={status}>
              {count} messages currently in {status} status
            </Typography>
          ))}
        </CardContent>
      </Card>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        {/* Pie Chart */}
        <div style={{ width: "45%" }}>
          <h3>Message Status Breakdown</h3>
          <PieChart width={400} height={400}>
            <Pie
              data={pieData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill="#8884d8"
              label
            >
              {pieData.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={pieColors[index % pieColors.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </div>

        {/* Time Series Chart */}
        <div style={{ width: "50%" }}>
          <h3>Message Status Over Time</h3>
          <LineChart width={500} height={400} data={timeSeriesData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            {Object.keys(statusCounts || {}).map((status, index) => (
              <Line
                key={status}
                type="monotone"
                dataKey={status}
                stroke={pieColors[index % pieColors.length]}
                name={status}
              />
            ))}
          </LineChart>
        </div>
      </div>

      {/* Table */}
      <div>
        <h3>Interactions Table</h3>
        <StyledTable columns={tableColumns} data={campaignInteractions || []} />
      </div>
    </div>
  );
};

export default InteractionsTab;
