import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../axiosConfig";

export const createCampaign = createAsyncThunk(
  "campaign/createCampaign",
  async ({ businessId, campaign }) => {
    const response = await axiosInstance.post(
      `sms/business/${businessId}/campaign`,
      campaign
    );
    return response.data;
  }
);

export const sendCampaign = createAsyncThunk(
  "campaign/sendCampaign",
  async ({ businessId, campaignId }) => {
    console.log("thunk send campaign");
    const response = await axiosInstance.post(
      `sms/business/${businessId}/campaign/${campaignId}/send`,
      {}
    );
    return response.data;
  }
);

export const updateCampaign = createAsyncThunk(
  "campaign/updateCampaign",
  async ({ businessId, campaignId, campaign }) => {
    const response = await axiosInstance.put(
      `sms/business/${businessId}/campaign/${campaignId}`,
      campaign
    );
    return response.data;
  }
);

export const getCampaign = createAsyncThunk(
  "campaign/getCampaign",
  async ({ businessId, campaignId }) => {
    const response = await axiosInstance.get(
      `sms/business/${businessId}/campaign/${campaignId}`
    );
    return response.data;
  }
);

export const getCampaignInteractions = createAsyncThunk(
  "campaign/getCampaign/interactions",
  async ({ businessId, campaignId }) => {
    const response = await axiosInstance.get(
      `sms/business/${businessId}/campaign/${campaignId}/interactions`
    );
    return response.data;
  }
);

export const deleteCampaign = createAsyncThunk(
  "campaign/deleteCampaign",
  async ({ businessId, campaignId }) => {
    const response = await axiosInstance.delete(
      `sms/business/${businessId}/campaign/${campaignId}`
    );
    return response.data;
  }
);

export const getCampaigns = createAsyncThunk(
  "campaign/getCampaigns",
  async (businessId) => {
    const response = await axiosInstance.get(
      `sms/business/${businessId}/campaign`
    );
    return response.data;
  }
);

const campaignSlice = createSlice({
  name: "campaign",
  initialState: {
    campaign: null,
    campaignInteractions: [],
    campaigns: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCampaigns.fulfilled, (state, action) => {
        state.loading = false;
        state.campaigns = action.payload;
      })
      .addCase(getCampaign.fulfilled, (state, action) => {
        state.loading = false;
        state.campaign = action.payload;
      })
      .addCase(getCampaignInteractions.fulfilled, (state, action) => {
        state.loading = false;
        state.campaignInteractions = action.payload;
      })
      .addCase(sendCampaign.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteCampaign.fulfilled, (state, action) => {
        state.loading = false;
        const { businessId, campaignId } = action.payload;
        state.campaigns = state.campaigns.filter(
          (campaign) =>
            campaign.businessId !== businessId ||
            campaign.campaignId !== campaignId
        );
      })
      .addCase(createCampaign.fulfilled, (state, action) => {
        state.loading = false;
        state.campaign = action.payload;
      })
      .addCase(updateCampaign.fulfilled, (state, action) => {
        state.loading = false;
        state.campaign = action.payload;
      })
      .addMatcher(
        (action) =>
          action.type.startsWith("campaign/") &&
          action.type.endsWith("/pending"),
        (state) => {
          state.error = null;
        }
      )
      .addMatcher(
        (action) =>
          action.type.startsWith("campaign/") &&
          action.type.endsWith("/pending") &&
          action.type !== "campaign/getCampaign/interactions/pending",
        (state) => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        (action) =>
          action.type.startsWith("campaign/") &&
          action.type.endsWith("/rejected"),
        (state, action) => {
          console.log("error: ", action.error);
          state.loading = false;

          // Check for response inside the error
          if (action.error.response && action.error.response.data) {
            // If response data is available, use it as the error message
            state.error = action.error.response.data;
          } else {
            // Otherwise, use the generic error message
            state.error = action.error.message;
          }
        }
      );
  },
});

export default campaignSlice.reducer;
